import React, { useState, useEffect } from 'react'
import { Box, Input, Flex, Text, Divider, Button, Image } from '@chakra-ui/react'
import FirstDemo from '../../Assets/firstlookdemo.jpg'
import Axios from 'axios'


function FirstLook() {
    const [FirstLookData, setFirstLookData] = useState([])
    useEffect(() => {
        Axios.get('https://api.dev.reviewtrend.in/rtapi/v1/firstlook/get',{withCredentials:true}).then(res => {
            setFirstLookData(res.data);
        }).catch(err => {
            console.log("error in getting the trailers data")
        })
    }, [])
    const deleteFirstLook = (id) => {
        Axios.delete(`https://api.dev.reviewtrend.in/rtapi/v1/firstlook/delete/${id}`,{withCredentials:true}).then(res => {
            //console.log('Deleted post')
            alert("post deleted successfully")
        }).catch(err => {
            console.log(err)
        })
    }
    return (
        <Box flex="3" overflowY="scroll" height="100vh" bg="white">
            <Flex bg="white" zIndex="999" position="sticky" top="0" justifyContent="flex-end" alignItems="center" height="50px" borderBottom="1px solid black">
                <Input marginRight="10px" height="30px" borderRadius="20px" outline="none"
                    width="200px" type="text" placeholder="search"
                    border="1px solid #00a7ac"></Input>
            </Flex>
            <Flex flexWrap="wrap">
                {FirstLookData.map((data) => {
                    let date = new Date(data.updateDate);
                    return <Flex boxShadow="0 0 6px 3px rgba(146,146,146,0.10)" m={2} p={2} key={data.movieId} width="350px" height="340px" border="1px solid black" direction="column">
                        <Flex justifyContent="space-between" height="100%" direction="column">
                            <Image src={data.posterURL} mr={1} border="1px solid black"
                                width="100%" height="180px"></Image>
                            <Flex justifyContent="space-around">
                                <Text size="sm" p={1} width="200px" borderRadius="20px" border="1px solid black">{data.movieTitle}</Text>                                <Flex justifyContent="space-between">
                                    <Text mr={2}>{date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate()}</Text>
                                    <Text>00:00</Text>
                                </Flex>
                            </Flex>
                            <Text  size="sm" p={1} width="100%" borderRadius="20px" border="1px solid black">{data.mediaLink==null?"Empty":data.mediaLink}</Text>
                        </Flex>
                        <Divider my={1} color="black"></Divider>
                        <Flex justifyContent="space-around">
                            <Button variant="link" textDecoration="underline">Edit</Button>
                            <Button variant="link" textDecoration="underline">Re-Publish</Button>
                            <Button variant="link" textDecoration="underline">Un-Publish</Button>
                            <Button onClick={e => deleteFirstLook(data.movieId)} color="tomato" variant="link" textDecoration="underline">Delete</Button>
                        </Flex>
                    </Flex>
                }).reverse()}
            </Flex>
        </Box>
    )
}

export default FirstLook
