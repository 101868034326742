import React, { useState, useEffect } from 'react'
import { Box, Input, Flex, Text, Divider, Button, Image } from '@chakra-ui/react'
import Axios from 'axios'

function Trending() {
    const [TrendingList, setTrendingList]=useState([]);
    useEffect(() => {
        console.log("GETs Treding DATa")
        Axios.get(`https://api.dev.reviewtrend.in/rtapi/v1/trend`,{withCredentials:true}).then(res=>{
            setTrendingList(res.data);
            console.log("Trending data",res.data);
        }).catch(err=>{
            console.log("Error in fetching trending list-->",err);
        })
    }, [])

    const removeTrending=(id)=>{
        console.log("//||Removing from trend",id);
        Axios.patch(`https://api.dev.reviewtrend.in/rtapi/v1/trend/${id}?trend=false`,null,{withCredentials:true}).then(res=>{
            alert("Added to Trending");
        }).catch(err => {
            console.log(err)
        })
    }
    
    return (
        <Box flex="3" overflowY="scroll" height="100vh" bg="white">
            <Flex bg="white" zIndex="999" position="sticky" top="0" justifyContent="flex-end" alignItems="center" height="50px" borderBottom="1px solid black">
                <Input marginRight="10px" height="30px" borderRadius="20px" outline="none"
                    width="200px" type="text" placeholder="search"
                    border="1px solid #00a7ac"></Input>
            </Flex>
            <Flex flexWrap="wrap">
                {TrendingList.map((data) => {
                    return <Flex alignItems={"center"} boxShadow="0 0 6px 3px rgba(146,146,146,0.10)" m={2} p={2} key={data.movieId} width="300px" height="330px" border="1px solid black" direction="column">

                            <Image onClick={e=>removeTrending(data.movieId)} cursor={"pointer"} src={data.posterURL} mr={1} border="1px solid black" width="180px"
                                height="265px">
                                </Image>
                        <Divider my={1} color="black"></Divider>
                        <Text backgroundColor={"#00a7ac"} fontWeight={"600"} color="white" size="sm" p={1} borderRadius="20px" border="1px solid black">{data.movieTitle}</Text>
                    </Flex>
                }).reverse()}
            </Flex>
        </Box>
    )
}

export default Trending
