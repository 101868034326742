import React from 'react'
import { Box, Flex, Image } from '@chakra-ui/react'
import Logo from '../Assets/logo.png'
import Upload from '../Assets/UPLOADS.png'
import { Link, useLocation } from 'react-router-dom'
import Left from '../Assets/left.png'

const leftItems = [
    'Just Released', 'first look', 'trailors', 'notifications'
];
const rootItmes = [
    '/postJustRelease', '/postFirstLook', '/postTrailers', "/postNotification"
]
function Side1() {
    const location = useLocation().pathname;
    return (
        <Flex direction="column" bg="#dddddd" flex="1">
            <Image position="fixed" top="10" left="20" src={Left}></Image>
            <Box>
                <Image height="100px" src={Upload} objectFit="none"></Image>
            </Box>
            <Flex mt={10} direction="column">
                {leftItems.map((Item, idx) => (
                    <Flex cursor="pointer" my="10px" key={Item} alignItems="center">
                        <Box flex="1" height="1px" border={location !== rootItmes[idx] && "1px solid #00a7ac"}></Box>
                        <Flex bg={location === rootItmes[idx] && "white"} textTransform="uppercase"
                            justifyContent="center" border flex="3" border="1px solid #00a7ac"
                            borderRadius="15px"><Link to={rootItmes[idx]}>{Item}</Link></Flex>
                        <Box flex="1" border={location === rootItmes[idx] && "1px solid #00a7ac"}></Box>
                    </Flex>
                ))}
            </Flex>
        </Flex >
    )
}

export default Side1
