import React from 'react'
import { Box, Flex, Image } from '@chakra-ui/react'
import Header from './Header'
import Side1 from '../Components/Side1';
import Side2 from '../Components/Side2';
import Side3 from '../Components/Side3';
import JustReleased from './JustReleased';
import BottomLayer from '../Assets/BottomLayer.svg'
import BottomLayer1 from '../Assets/BottomLayer1.svg'

function Home({ comp }) {
    return (
        <Flex direction="column" height="100vh">
            <Header />
            <Flex pt={10} bg="#dddddd" height="100vh">
                <Side1 />
                {comp}
                <Side3 />
            </Flex>
            <Image height="400px" position="bottom" right="0px" bottom="0px" position="fixed" src={BottomLayer}></Image>
            <Image position="bottom" right="0px" bottom="0px" position="fixed" src={BottomLayer1}></Image>
        </Flex>
    )
}

export default Home
