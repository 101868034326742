import React, { useState } from 'react'
import { Box, Button, Flex, Image, Input, Stack, Text } from '@chakra-ui/react'
import Layer from '../Assets/Layer.svg'
import { useHistory } from 'react-router-dom'
import Layer1 from '../Assets/Layer1.svg'
import LoginIcon from '../Assets/loginIcon.png'
import Edit from '../Assets/edit.svg'
import Logo from '../Assets/logo.png'
import Axios from 'axios';
import '../App.css'

function NewLoginPage() {
    const history = useHistory();
    const [userName, setuserName] = useState("")
    const [password, setpassword] = useState("")
    const handleIcon = e => {
        return <Image width="12px" src={e}></Image>
    }
    const submitLogin = (e) => {
        e.preventDefault();
        var formData=new FormData();
        formData.append("EmailID",userName);
        formData.append("Password",password);
        Axios.post('https://api.dev.reviewtrend.in/api/v1/security/user/authenticate',formData,{
            withCredentials:true
        }).then(res=>{
            // console.log("Cookies",res);
            if(res.status==200){
                history.push('/justRelease') 
            }
        })
        // if (userName === 'admin' && password === "AdminPublic") {
        //     history.push('/justRelease')
        // } else {
        //     alert('Invalid Credientials')
        // }
    }
    return (
        <form onSubmit={submitLogin}>
            <Flex direction="column" justifyContent="space-between" paddingTop="70px"
                dir="column" width='100%' height="100vh" bg="#FFFFFF" alignItems="center" >
                <Flex position="absolute" justifyContent="space-between" alignItems="center" height="50px" bg="#00a7aC" width="100%" top="0px">
                    <Text color="white" marginLeft="50px" fontWeight="600" fontSize="20px">Review Trend</Text>
                </Flex>
                <Flex position="fixed" zIndex="9999" bg="white" padding="0px 70px 0px 70px" direction="column" 
                 justifyContent="space-around" alignItems="center" width="500px" height="400px" border="1px solid #007e7c" borderRadius="40px">
                    <Image height="100px" src={LoginIcon}></Image>
                    <Text color="#00a7aC" fontWeight="600" fontSize="20px">Review Trend</Text>
                    <Flex width="100%" justifyContent="flex-end">
                        <Text color="#00a7ac" fontWeight="600" fontSize="16px">Login</Text>
                    </Flex>
                    <Input name="userName" onChange={e => setuserName(e.target.value)} required borderColor="#00A7AC" borderRadius="20px" type="text" placeholder="Username"></Input>
                    <Input name="password" onChange={e => setpassword(e.target.value)} required borderColor="#00A7AC" borderRadius="20px" type="Password" placeholder="Password"></Input>
                    <Flex width="100%" justifyContent="flex-end">
                        <Text textDecoration="underline" cursor="pointer">Forgot?</Text>
                    </Flex>
                    <Button type="submit" _hover="none" rightIcon={handleIcon(Edit)} bg="#00a7aC" borderRadius="20px"
                        color="white" size="sm">Login</Button>
                </Flex>
                <Image src={Logo} position="fixed" zIndex="9999" bottom="10px" height="220px" right="0px"></Image>
                <Image src={Layer} position="fixed" bottom="0px"></Image>
                <Image src={Layer1} position="fixed" bottom="0px"></Image>
                {/* <Box width='100%' position="fixed" bottom='0px'
             height="100px" borderTopRadius="50px" bg="#00A7AC"></Box>
            <Box width='100%' height="150px" borderTopRadius="50px" bg="#8DD7D9"></Box> */}
            </Flex>
        </form>

    )
}

export default NewLoginPage
