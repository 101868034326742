import React, { useState } from 'react'
import { Box, Flex, Input, Text, Button, Image } from '@chakra-ui/react'
import TrailDemo from '../Assets/trailerdemo.jpg'
import Axios from 'axios'

function PostTrailer() {
    const [movieTitle, setmovieTitle] = useState("")
    const [youtubeLink, setyoutubeLink] = useState("")
    const [moviePoster, setmoviePoster] = useState(null)
    const [preview, setpreview] = useState(null)
    const config = {
        headers: { 'content-type': 'multipart/form-data'},withCredentials:true
    }
    let formData = new FormData();
    let reader = new FileReader();
    const showPreview = (e) => {
        setmoviePoster(e)
        reader.onloadend = () => {
            setpreview(reader.result)
        }
        reader.readAsDataURL(e)
    }
    const submitTrailers = (e) => {
        e.preventDefault();
        formData.append('movieTitle', movieTitle)
        formData.append('moviePoster', moviePoster)
        formData.append('youtubeLink', youtubeLink)
        Axios.post('https://api.dev.reviewtrend.in/rtapi/v1/trailers/new', formData, config).then(res => {
            //console.log(res)
            { res.status === 200 && alert("Data Uploaded Successfully") }
        }).catch(err => {
            console.log(err)
            alert("error in uploading data")
        })
    }
    return (
        <form onSubmit={submitTrailers}>
            <Flex height="400px" borderRadius="20px" bg="white" my="50px"
                border="1px solid #00a7ac" flex="3" alignItems="center" padding={4}
                boxShadow="0 0 6px 3px rgba(146,146,146,0.10)">
                <Flex direction="column">
                    {/**width="360px" height="163px" */}
                    <Image src={preview} width="180px" height="265px" border="1px solid black"
                        mr={4} boxShadow="0 0 6px 3px rgba(146,146,146,0.10)"></Image>
                    <Input type="file" name="moviePoster" onChange={e => showPreview(e.target.files[0])} border="none" title="none"></Input>
                </Flex>
                <Flex alignItems="flex-start" width="100%" height="100%" justifyContent="space-around" direction="column">
                    <Input border="1px solid black" width="200px" type="text"
                        borderRadius="20px" name="movieTitle" onChange={e => setmovieTitle(e.target.value)} placeholder="Movie Name"></Input>
                    <Input width="200px" borderRadius="20px" mr={4} type="text"
                        name="youtubeLink" onChange={e => setyoutubeLink(e.target.value)} placeholder="paste link here..."></Input>
                    <Text textDecoration="underline" >Clear Text</Text>
                    <Button type="submit" outline="none" width="100px" variant="contained" color="white" borderRadius="20px" bg="#00a7ac">Publish</Button>

                </Flex>
            </Flex>
        </form>
    )
}

export default PostTrailer
