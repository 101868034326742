import React from 'react';
import {Box,Text,Image} from '@chakra-ui/react';
import LandingImage from '../../Assets/newassets/Landingpage.svg';
import Reviews from '../../Assets/newassets/Reviews Theater symbol.svg';
import Trailer from '../../Assets/newassets/Trailer plat symbol.svg';
import Community from '../../Assets/newassets/Community symbol.png';
import '../../style.css';
import card1 from '../../Assets/newassets/01@2x.svg';
import card2 from '../../Assets/newassets/02@2x.svg';
import card3 from '../../Assets/newassets/03@2x.svg';
import card4 from '../../Assets/newassets/04@2x.svg';
import boxes from '../../Assets/newassets/Four_boxes.svg';
import whiteLogo from '../../Assets/newassets/RTicon_White.svg';
import fb from '../../Assets/newassets/icons8-facebook.svg';
import googlePlay from '../../Assets/newassets/icons8-google-play.svg';
import instagram from '../../Assets/newassets/icons8-instagram.svg';
import twitter from '../../Assets/newassets/icons8-twitter.svg';
import stars from '../../Assets/newassets/Landing page stars.svg';
import slidebar from '../../Assets/newassets/3.svg';
import playstore from '../../Assets/newassets/Web_AppleStore.svg';
import appstore from '../../Assets/newassets/Web_GoogleStore.svg';
import mail from '../../Assets/newassets/mail.svg';
import '../../Assets/fonts/Segoe UI.ttf';
import '../../Assets/fonts/Segoe UI Bold.ttf';
import '../../Assets/fonts/Cambria.ttf';


function NewHomePage(){
    return (
        <Box width={"100%"}>
            <Box className='home'>
               <Box d={"flex"} justifyContent="space-between">
                <Box display={"flex"} justifyContent="space-between" borderBottomRightRadius={48} px={8} alignItems="center" backgroundColor={"#04a3aa"} w={"600px"}>
                    <Image boxSize={24} src={whiteLogo}></Image>
                    <Text mb={4} color={"white"} fontSize={68} fontWeight="bold" fontFamily="Segoe UI">Review Trend</Text>
                </Box>
                <Box my={4} display={"flex"} p={4} justifyContent="center">
                    <Text fontSize={24} mx={8} fontFamily={"Cambria"} textDecoration={"underline"} color={"white"}>Home</Text>
                    <Text fontSize={24}  mx={8} fontFamily={"Cambria"} textDecoration={"underline"} color={"white"}>About</Text>
                    <Text fontSize={24}  mx={8} fontFamily={"Cambria"} textDecoration={"underline"} color={"white"}>Contact</Text>
                    <Text fontSize={24}  mx={8} fontFamily={"Cambria"} textDecoration={"underline"} color={"white"}>FAQs</Text>                
                </Box>
               </Box>
               <Box px={[20,26,30,36]} py={20} justifyContent="space-between" display={"flex"}>
                <Box display={"flex"} justifyContent="center" flexDir={"column"}>
                    <Text w={["400px","450px","500px","700px"]} lineHeight={1} fontFamily="Segoe UI" fontSize={[24,40,66,140]} fontWeight="bold" color={"white"}>it is time to Review.</Text>
                    <Text fontSize={60} fontWeight="bold" fontFamily={"Caveat"} color={"white"}>your review read by world.</Text>
                    <Image mt={12} src={stars} width="28vw"></Image>
                </Box>
                <Box display={"flex"} flexDir="column" alignItems={"center"}>
                    <Image height={["60vh"]} src={slidebar}></Image>
                    <Text textAlign={"center"} w={"380px"} fontFamily="Cambria" fontSize={36} color={"white"}>Get app now on.</Text>
                    <Box my={6} display={"flex"}>
                        <a href='https://play.google.com/store/apps/details?id=com.trendworld.reviewtrend'><Image mx={4} src={appstore}></Image></a>
                        <a href='https://play.google.com/store/apps/details?id=com.trendworld.reviewtrend'><Image mx={4} src={playstore}></Image></a>
                    </Box>
                </Box>
               </Box>
            </Box>
           <Box pt={20} width={"100%"} justifyContent="space-around" display={"flex"}>
            <Box display={"flex"} flexDir="column" justifyContent="center" alignItems={"center"} textAlign={"center"}>
                <Image src={Reviews}></Image>
                <Text fontFamily={"Segoe UI"} my={4} fontSize={24} fontWeight={"bold"}>Reviews</Text>
                <Text fontFamily={"Segoe UI"} width={"300px"}>A lot of cinema audience would like to check out how the movie is and what the
reviews are soon as it releases. For people like them, this is a great platform to sign on! Not
just skimming through others’ reviews, you can write your own reviews too! Rate a movie
alongside dropping your review on it. By rating on different departments of the movie, you
can express your genuine review on the movie.</Text>
            </Box>
            <Box display={"flex"} flexDir="column"  justifyContent="center" alignItems={"center"} textAlign={"center"}>
                <Image src={Trailer}></Image>
                <Text fontFamily={"Segoe UI"} my={4} fontSize={24} fontWeight={"bold"}>Trailers</Text>
                <Text fontFamily={"Segoe UI"} width={"300px"}>This is the best platform to watch the trailers of movies all at from one place! Trailer
of every released movie can be watched from here.</Text>
            </Box>
            <Box fdisplay={"flex"} flexDir="column"  justifyContent="center" alignItems={"center"} textAlign={"center"}>
                <Image ml={20} src={Community}></Image>
                <Text fontFamily={"Segoe UI"}  my={4} fontSize={24} fontWeight={"bold"}>Community</Text>
                <Text fontFamily={"Segoe UI"} width={"300px"}>This community has been created for movie lovers. Not only can you review the
movies, but you could also follow the people who post their reviews. You can increase the
people who follow you from the reviews that you write! With the ability to like a user’s
reviews, people will appreciate you with their likes on something you’ve written, and you can
also like what opinion others have put up.</Text>
            </Box>
           </Box>
           <Box className='bgBox'>
            <Box px={48} pt={48} pb={36} display={"flex"} flexDir="column">
                <Box mt={20} display={"flex"} flexDir="column">
                    <Text color={"white"} fontSize={40}>About</Text>
                    <Text color={"white"} fontFamily={"Segoe UI"} fontWeight="bold" fontSize={76}>Review Trend</Text>
                </Box>
                <Box  display={"flex"} alignItems="center" justifyContent="end">
                    <Box alignItems={"end"} display={"flex"} flexDir="column">
                        <Text color={"white"} w={"180px"} textAlign="end" fontSize={46}>Reviewer Profile</Text>
                        <Text color={"white"} w={"500px"} textAlign="end" fontSize={26}>Fill up your profile along with your place and profession and upload your profile picture. Let the community know who you are!</Text>
                    </Box>
                    <Image  h={"70vh"} ml={4} src={card1}></Image>
                </Box>

                <Box display={"flex"} alignItems="center" justifyContent="flex-start">
                    <Image  h={"70vh"} mr={4} src={card2}></Image>
                    <Box  display={"flex"} flexDir="column">
                        <Text color={"white"} w={"180px"} fontSize={46}>Staring and Reviews</Text>
                        <Text color={"white"} w={"450px"} fontSize={26}>You can write reviews and put forward your opinions. Just not writing in words, you
also have the ability of rating via stars for different departments of the movie generating an
overall review! And you can always see what others are saying about the movie in Public
Reviews.</Text>
                    </Box>
                    
                </Box>
                <Box display={"flex"} alignItems="center" justifyContent="end">
                    <Box alignItems={"end"} display={"flex"} flexDir="column">
                        <Text color={"white"} w={"150px"} textAlign="end" fontSize={46}>List your reviews</Text>
                        <Text color={"white"} w={"500px"} textAlign="end" fontSize={26}>If you ever wanted to look back at what you have spoken about a movie, you can
always check your reviews in the My Reviews section</Text>
                    </Box>
                    <Image  h={"70vh"} ml={4} src={card3}></Image>
                </Box>
                <Box display={"flex"} alignItems="center" justifyContent="flex-start">
                    <Image h={"70vh"} mr={4} src={card4}></Image>
                    <Box  display={"flex"} flexDir="column">
                        <Text color={"white"} w={"250px"} fontSize={46}>First Looks, Trailers<br/> and Updates</Text>
                        <Text color={"white"} w={"550px"} fontSize={26}>In addition to writing reviews, you can check out the first looks, trailers and latest
updates too!</Text>
                    </Box>
                </Box>
                <Box mt={10} display={"flex"} alignItems="center" justifyContent="space-between">
                    <Image h={"35vh"} ml={20} src={boxes}></Image>
                    <Box  display={"flex"} flexDir="column" justifyContent={"end"} alignItems={"end"}>
                        <Text fontFamily={"Cambria"} color={"white"} w={"180px"} textAlign="end" fontSize={46}>About us</Text>
                        <Text fontFamily={"Cambria"} color={"white"} w={"600px"} textAlign="end" fontSize={26}>Lorem ipsum dolor sit amet. Qui veritatis commodi a internos cupiditate ut velit illum et quidem quia et adipisci neque sed similique omnis! Ab quasi modi molestiae soluta id voluptatem nesciunt ea laboriosam beatae quibusdam nihil aut nisi voluptas?</Text>
                    </Box>
                </Box>
                <Box mt={10} display={"flex"} alignItems="center" justifyContent="space-between">
                    <Box/>
                    <Box  display={"flex"} flexDir="column" justifyContent={"end"} alignItems={"end"}>
                        <Text fontFamily={"Cambria"} color={"white"} w={"220px"} textAlign="end" fontSize={46}>contact us</Text>
                        <Text fontFamily={"Cambria"} color={"white"} w={"360px"} textAlign="end" fontSize={18}>info.reviewtrend@gmail.com</Text>
                        <Image src={mail}></Image>
                    </Box>
                </Box>
                </Box>
            </Box>
            <Box display={"flex"} flexDir={"column"} alignItems="center" backgroundColor={"#00a7ac"}>
                <Image display={"flex"} justifyContent="center" alignItems={"center"} backgroundColor={"#00a7ac"} w="100px" borderRadius={"50%"} height={24} mt={-12} src={whiteLogo}></Image>
                <Box display={"flex"} justifyContent="center">
                    <Image mx={10} height={16} src={fb}></Image>
                    <Image mx={10} height={16} src={instagram}></Image>
                    <Image mx={10} height={16} src={twitter}></Image>
                    <Image mx={10} height={16} src={googlePlay}></Image>
                </Box>
                <Box my={2} display={"flex"} justifyContent="center">
                    <Text fontSize={16} mx={8} fontFamily={"Cambria"} textDecoration={"underline"} color={"white"}>Home</Text>
                    <Text fontSize={16}  mx={8} fontFamily={"Cambria"} textDecoration={"underline"} color={"white"}>About</Text>
                    <Text fontSize={16}  mx={8} fontFamily={"Cambria"} textDecoration={"underline"} color={"white"}>Contact</Text>
                    <Text fontSize={16}  mx={8} fontFamily={"Cambria"} textDecoration={"underline"} color={"white"}>FAQs</Text>                
                </Box>
                <Box my={4} display={"flex"} justifyContent="center">
                    <Text fontSize={16} mx={8} fontFamily={"Cambria"} textDecoration={"underline"} color={"white"}>Privacy Policy</Text>
                    <Text fontSize={16}  mx={8} fontFamily={"Cambria"} textDecoration={"underline"} color={"white"}>Terms</Text>         
                </Box>
                <Text display={"flex"} justifyContent="center" fontSize={14}  mx={8} fontFamily={"Cambay"} color={"white"}>© 2021-2022 All rights reserved.</Text>
            </Box>
        </Box>
    )
}

export default NewHomePage;