import React from 'react'
import { Box, Flex, Image } from '@chakra-ui/react'
import Dash from '../Assets/DASH BOARD.png'
import { Link, useLocation } from 'react-router-dom'
import Right from '../Assets/right.png'

const rootItmes = [
    '/justRelease', '/firstLook', '/trailors', "/trending"
]
const rightItems = [
    'just released', 'first look', 'trailers', 'Trending', 'verified accounts', 'report abuse'
]
function Side3() {
    const location = useLocation().pathname;
    return (
        <Flex direction="column" bg="#dddddd" flex="1">
            <Flex my="50px" direction="column">
                <Image src={Dash} objectFit="none"></Image>
                {rightItems.map((Item, idx) => (
                    <Flex key={Item} cursor="pointer" my="10px" key={Item} alignItems="center">
                        <Box flex="1" height="1px" border={location === rootItmes[idx] && "1px solid #00a7ac"}></Box>
                        <Flex bg={location === rootItmes[idx] && 'white'} textTransform="uppercase" justifyContent="center"
                            border flex="3" border="1px solid #00a7ac"
                            borderRadius="15px"><Link to={rootItmes[idx]}>{Item}</Link></Flex>
                        <Box flex="1" border={location !== rootItmes[idx] && "1px solid #00a7ac"}></Box>
                    </Flex>
                ))}
                <Image height="200px" bottom="5" position="fixed" right="10" zIndex="999" src={Right}></Image>
            </Flex>
        </Flex >
    )
}

export default Side3
