import React, { useState, useEffect } from 'react'
import { Box, Input, Flex, Text, Divider, Button, Image } from '@chakra-ui/react'
import TrailDemo from '../../Assets/trailerdemo.jpg'
import Axios from 'axios'

function Trailers() {
    const [TrailersData, setTrailersData] = useState([])
    useEffect(() => {
        Axios.get('https://api.dev.reviewtrend.in/rtapi/v1/trailers/get',{withCredentials:true}).then(
            res => {
                //console.log(res.data);
                setTrailersData(res.data)
            }
        ).catch(Err => {
            console.log("Error in getting the trailers data")
        })
    }, [])
    const deleteTrailers = (id) => {
        Axios.delete(`https://api.dev.reviewtrend.in/rtapi/v1/trailers/delete/${id}`,{withCredentials:true}).then(res => {
            //console.log('Deleted post')
            alert("post deleted successfully")
        }).catch(err => {
            console.log(err)
        })
    }
    return (
        <Box flex="3" overflowY="scroll" height="100vh" bg="white">
            <Flex bg="white" zIndex="999" position="sticky" top="0" justifyContent="flex-end" alignItems="center" height="50px" borderBottom="1px solid black">
                <Input marginRight="10px" height="30px" borderRadius="20px" outline="none"
                    width="200px" type="text" placeholder="search"
                    border="1px solid #00a7ac"></Input>
            </Flex>
            <Flex flexWrap="wrap">
                {TrailersData.map((data) => {
                    let date = new Date(data.updateDate);
                    return <Flex key={data.movieId} boxShadow="0 0 6px 3px rgba(146,146,146,0.10)" m={2} p={2} key={data} width="350px" height="310px" border="1px solid black" direction="column">
                        <Flex>
                            <Image src={data.moviePosterUrl} mr={1} border="1px solid black" width="150px"
                                height="210px"></Image>
                            <Flex justifyContent="space-around" direction="column">
                                <Text size="sm" p={1} borderRadius="20px" border="1px solid black">{data.movieTitle}</Text>
                                <Flex justifyContent="space-between">
                                    <Text mr={2}>{date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate()}</Text>
                                    <Text>00:00</Text>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Text size="sm" p={1} m={2} borderRadius="20px"
                            overflow="hidden" textOverflow="ellipsis" border="1px solid black">{data.youtubeLink}</Text>
                        <Divider my={1} color="black"></Divider>
                        <Flex justifyContent="space-around">
                            <Button variant="link" textDecoration="underline">Edit</Button>
                            <Button variant="link" textDecoration="underline">Re-Publish</Button>
                            <Button variant="link" textDecoration="underline">Un-Publish</Button>
                            <Button onClick={e => deleteTrailers(data.movieId)} color="tomato" variant="link" textDecoration="underline">Delete</Button>
                        </Flex>
                    </Flex>
                }).reverse()}
            </Flex>
        </Box>
    )
}

export default Trailers
