import React, { useState } from 'react'
import { Box, Flex, Input, Text, Button, Image } from '@chakra-ui/react'
import JustDemo from '../Assets/justReleasedemo.jpg'
import Axios from 'axios'

function Side2() {
    const [movieTitle, setmovieTitle] = useState("")
    const [releaseDate, setReleasing] = useState("")
    const [poster, setposter] = useState(null)
    const [preview, setpreview] = useState(null)
    const config = {
        headers: { 'content-type': 'multipart/form-data' },withCredentials:true
    }
    let formData = new FormData();
    let reader = new FileReader();
    const showPreview = (e) => {
        setposter(e)
        reader.onloadend = () => {
            setpreview(reader.result)
        }
        reader.readAsDataURL(e)
    }
    const submitData = (e) => {
        e.preventDefault();
        formData.append('movieTitle', movieTitle)
        formData.append('releaseDate', releaseDate)
        formData.append('moviePoster', poster)
        Axios.post("https://api-staging.reviewtrend.in/rtapi/v1/justreleased/new", formData, config)
            .then(response => {
                //console.log(response);
                { response.status === 200 && alert("Data Uploaded Successfully") }
            })
            .catch(error => {
                console.log(error);
                alert("error in uploading image")
            });
    }
    return (
        <form onSubmit={submitData}>
            <Flex height="400px" bg="#dddddd" borderRadius="20px" bg="white" my="50px"
                border="1px solid #00a7ac" flex="3" alignItems="center" padding={4}
                boxShadow="0 0 6px 3px rgba(146,146,146,0.10)">
                <Flex direction="column">
                    {/**width="360px" height="163px" */}
                    <Image src={preview} width="180px" height="265px" border="1px solid black"
                        mr={4} boxShadow="0 0 6px 3px rgba(146,146,146,0.10)"></Image>
                    <Input required type="file" border="none" name="moviePoster"
                        onChange={e => showPreview(e.target.files[0])} title="none"></Input>
                </Flex>
                <Flex alignItems="flex-start" width="100%" height="100%" justifyContent="space-around" direction="column">
                    <Input required border="1px solid black" width="200px" type="text"
                        borderRadius="20px" name="movieTitle" onChange={e => setmovieTitle(e.target.value)} placeholder="Movie Name"></Input>
                    <Flex alignItems="flex-start" width="100%">
                        <Input required width="200px" borderRadius="20px" mr={4} type="text"
                            name="releaseDate" onChange={e => setReleasing(e.target.value)} placeholder="YYYY-MM-DD"></Input>
                        <Text textDecoration="underline" >Clear Text</Text>
                    </Flex>
                    <Button type="submit" outline="none" width="100px" variant="contained" color="white" borderRadius="20px" bg="#00a7ac">Publish</Button>
                </Flex>
            </Flex>
        </form>
    )
}

export default Side2
