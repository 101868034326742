import React, { useState }  from 'react'
import { Box, Flex, Input, Text, Button, Image } from '@chakra-ui/react'
import FirstDemo from '../Assets/firstlookdemo.jpg'
import Axios from 'axios'

function PostFirstLook() {
    const [movieTitle, setmovieTitle] = useState("")
    const [movieurl, setmovieurl] = useState("")
    const [moviePoster, setmoviePoster] = useState(null)
    const [preview, setpreview] = useState(null)
    const config = {
        headers: { 'content-type': 'multipart/form-data'},withCredentials:true
    }
    let formData = new FormData();
    let reader = new FileReader();
    const showPreview = (e) => {
        setmoviePoster(e)
        reader.onloadend = () => {
            setpreview(reader.result)
        }
        reader.readAsDataURL(e)
    }
    const submitFirstLook = (e) => {
        e.preventDefault();
        formData.append('movieTitle', movieTitle)
        formData.append('moviePoster', moviePoster)
        formData.append('mediaLink', movieurl)
        Axios.post('https://api.dev.reviewtrend.in/rtapi/v1/firstlook/new', formData, config).then(res => {
            //console.log(res)
            { res.status === 200 && alert("Data Uploaded Successfully") }
        }).catch(err => {
            console.log(err)
            alert("error in uploading data")
        })
    }
    return (
        <form onSubmit={submitFirstLook}>
            <Flex height="400px" borderRadius="20px" bg="white" my="50px"
                border="1px solid #00a7ac" flex="3" alignItems="center" padding={4}
                boxShadow="0 0 6px 3px rgba(146,146,146,0.10)">
                <Flex direction="column" width="100%">
                    {/**width="360px" height="163px" */}
                    <Image src={preview} width="360px" height="163px" border="1px solid black"
                        mr={4} boxShadow="0 0 6px 3px rgba(146,146,146,0.10)"></Image>
                    <Input type="file" name="moviePoster" onChange={e => showPreview(e.target.files[0])} border="none" title="none"></Input>
                </Flex>
                <Flex direction="column" alignItems="flex-start" justifyContent="space-between" height="163px" width="100%">
                    <Input width="200px" borderRadius="20px" mr={4} type="text" name="movieTitle"
                        onChange={e => setmovieTitle(e.target.value)} placeholder="Movie Title"></Input>
                    <Input width="200px" borderRadius="20px" mr={4} type="text" name="movieUrl"
                    onChange={e => setmovieurl(e.target.value)} placeholder="Paste link here..."></Input>
                    <Text textDecoration="underline" >Clear Text</Text>
                    <Button type="submit" outline="none" width="100px" variant="contained" color="white" borderRadius="20px" bg="#00a7ac">Publish</Button>
                </Flex>
            </Flex>
        </form>
    )
}

export default PostFirstLook
