import logo from './logo.svg';
import './App.css';
import { Box, Flex } from '@chakra-ui/react'
import Login from './Components/Login';
import Home from './Components/Home'
import Side2 from './Components/Side2'
import JustReleased from './Components/JustReleased'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import PostFirstLook from './Components/postFirstLook';
import PostTrailer from './Components/postTrailer';
import PostNotification from './Components/postNotification'
import Trailers from './Components/posts/trailers'
import FirstLook from './Components/posts/firstLook'
import NewLoginPage from './Components/NewLoginPage';
import Trending from './Components/trending';
import NewHomePage from './Components/new_design/NewHomePage';

const roots = [
  { path: '/', comp: <Box /> },
  { path: '/postJustRelease', comp: <Side2 /> },
  { path: '/justRelease', comp: <JustReleased /> },
  { path: '/postFirstLook', comp: <PostFirstLook /> },
  { path: '/firstLook', comp: <FirstLook /> },
  { path: '/postTrailers', comp: <PostTrailer /> },
  { path: '/trailors', comp: <Trailers /> },
  { path: '/postNotification', comp: <PostNotification /> },
  { path: '/trending', comp: <Trending /> },
  { path: '/login', comp: <NewLoginPage /> },
]
function App() {
  return (
    <Router>
      <Box width="100%">
        <Switch>
          {roots.map((rootData) => (
            <Route key={rootData.path} exact path={rootData.path}>
              {rootData.path === "/" ? <NewHomePage /> :rootData.path==="/login"?<NewLoginPage/>: <Home comp={rootData.comp} />}
            </Route>
          ))}
          <Route component={Page404}></Route>
        </Switch>
      </Box>
    </Router>
  );
}
const Page404 = ({ location }) => (
  <Box bg="#D3D3D3" textAlign="center">
     <h2>No match found for <code>{location.pathname}</code></h2>
  </Box>
);
export default App;
