import React, { useState, useEffect } from 'react'
import { Box, Input, Flex, Text, Divider, Button, Image, cookieStorageManager } from '@chakra-ui/react'
import Axios from 'axios'
import TrendingLogo from '../Assets/trending.png';

function JustReleased() {
    const [JustReleaseData, setJustReleaseData] = useState([]);
    const [TrendingList, setTrendingList]=useState([]);
    useEffect(() => {
        console.log("GETs DATa")
        Axios.get('https://api.dev.reviewtrend.in/rtapi/v1/justreleased/releases',{withCredentials:true}).then(
            res => {
                //console.log(res.data);
                setJustReleaseData(res.data);
                console.log("Just Released Data-->",res.data);
            }
        ).catch(err => {
            console.log("Error at getting just release data",err)
        });
        Axios.get(`https://api.dev.reviewtrend.in/rtapi/v1/trend`,{withCredentials:true}).then(res=>{
            setTrendingList(res.data);
            console.log("Trending data",res.data);
        }).catch(err=>{
            console.log("Error in fetching trending list-->",err);
        })
    }, [])
    const deletePost = (id) => {
        Axios.delete(`https://api.dev.reviewtrend.in/rtapi/v1/justreleased/delete/${id}`,{withCredentials:true}).then(res => {
            //console.log('Deleted post')
            alert("post deleted successfully")
        }).catch(err => {
            console.log(err)
        })
    }

    const addTrending=(id)=>{
        console.log("//||",id);
        Axios.patch(`https://api.dev.reviewtrend.in/rtapi/v1/trend/${id}?trend=true`,null,{withCredentials:true}).then(res=>{
            alert("Added to Trending");
        }).catch(err => {
            console.log(err)
        })
    }
    
    return (
        <Box flex="3" overflowY="scroll" height="100vh" bg="white">
            <Flex bg="white" zIndex="999" position="sticky" top="0" justifyContent="flex-end" alignItems="center" height="50px" borderBottom="1px solid black">
                <Input marginRight="10px" height="30px" borderRadius="20px" outline="none"
                    width="200px" type="text" placeholder="search"
                    border="1px solid #00a7ac"></Input>
            </Flex>
            <Flex flexWrap="wrap">
                {JustReleaseData.map((data) => {
                    let date = new Date(data.releaseDate);
                    let upDatedDate = new Date(data.updateDate);
                    return <Flex boxShadow="0 0 6px 3px rgba(146,146,146,0.10)" m={2} p={2} key={data.movieId} width="350px" height="310px" border="1px solid black" direction="column">
                        <Flex>
                            <Image cursor={"pointer"} onClick={e=>addTrending(data.movieId)} src={data.posterURL} mr={1} border="1px solid black" width="180px"
                                height="265px">
                                </Image>
                            <Flex justifyContent="space-around" direction="column">
                                <Text size="sm" p={1} borderRadius="20px" border="1px solid black">{data.movieTitle}</Text>
                                {TrendingList.map((e,i)=>{
                                    if(e.movieId==data.movieId){
                                        return ( <Image src={TrendingLogo} w={16} h={16}></Image>);
                                    }
                                })
                                }
                                <Text size="sm" p={1} borderRadius="20px" border="1px solid black">
                                    {date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()}</Text>
                                <Flex justifyContent="space-between">
                                    <Text mr={2}>{upDatedDate.getFullYear() + '/' + (upDatedDate.getMonth() + 1) + '/' + upDatedDate.getDate()}</Text>
                                    <Text>00:00</Text>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Divider my={1} color="black"></Divider>
                        <Flex justifyContent="space-around">
                            <Button variant="link" textDecoration="underline">Edit</Button>
                            <Button variant="link" textDecoration="underline">Re-Publish</Button>
                            <Button variant="link" textDecoration="underline">Un-Publish</Button>
                            <Button onClick={e => deletePost(data.movieId)} color="tomato" variant="link" textDecoration="underline">Delete</Button>
                        </Flex>
                    </Flex>
                }).reverse()}
            </Flex>
        </Box>
    )
}

export default JustReleased
