import React, { useState }  from 'react'
import { Box, Flex, Input, Text, Button, Image } from '@chakra-ui/react'
import Note from '../Assets/notidemo.jpg'
import Axios from 'axios'

function PostNotification() {
    const [base64data,setbase64data]=useState("")
    const [title, setmovieTitle] = useState("")
    const [description, setmovieurl] = useState("")
    const [poster, setposter] = useState(null)
    const [preview, setpreview] = useState(null)
    let reader = new FileReader();
    let formData = new FormData();
    const config = {
        headers: { 'content-type': 'application/json'},withCredentials:true
    }
    const showPreview = (e) => {
        getBase64(e);
        setposter(e)
        reader.onloadend = () => {
            setpreview(reader.result)
        }
        reader.readAsDataURL(e)
    }
    const onLoad = fileString => {
        console.log(fileString.split(",")[1]);
        setbase64data(fileString.split(",")[1]);
      };
    const getBase64 = file => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          onLoad(reader.result);
        };
      };
    const submitNotification = (e) => {
        console.log("Sending Data is",base64data);
        e.preventDefault();
        formData.append('title', title)
        formData.append('body', description)
        formData.append('image',base64data)
        Axios.post('https://api.dev.reviewtrend.in/rtapi/v1/notifications', {
            'title':title,
            'body':description,
            'image':base64data
        }, config).then(res => {
            //console.log(res)
            { res.status === 200 && alert("Data Uploaded Successfully") }
        }).catch(err => {
            console.log(err)
            alert("error in uploading data")
        })
    }
    return (
        <form onSubmit={submitNotification}>
            <Flex height="400px" borderRadius="20px" bg="white" my="50px"
            border="1px solid #00a7ac" flex="3" alignItems="center" padding={4}
            boxShadow="0 0 6px 3px rgba(146,146,146,0.10)">
            <Flex direction="column" mr={4}>
                {/**width="360px" height="163px" */}
                <Image src={preview} width="180px" height="180px" border="1px solid black"
                    mr={4} boxShadow="0 0 6px 3px rgba(146,146,146,0.10)"></Image>
                <Input type="file" border="none" name="moviePoster"
                        onChange={e => showPreview(e.target.files[0])} title="none"></Input>
            </Flex>
            <Flex alignItems="flex-start" width="100%" height="100%" justifyContent="space-around" direction="column">
                <Input border="1px solid black" type="text"
                    borderRadius="20px" placeholder="Title" onChange={e => setmovieTitle(e.target.value)}></Input>
                <Input borderRadius="20px" mr={4} type="text" placeholder="Description" onChange={e => setmovieurl(e.target.value)}></Input>
                <Text textDecoration="underline">Clear Text</Text>
                <Button type="submit" outline="none" width="100px" variant="contained" color="white" borderRadius="20px" bg="#00a7ac">Publish</Button>
            </Flex>
        </Flex>
        </form>
    )
}

export default PostNotification
