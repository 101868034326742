import React from 'react'
import { Box, Flex, Text, Divider, Image } from '@chakra-ui/react'
import Person from '../Assets/account.svg'
import Home from '../Assets/Home.svg'
import { Link } from 'react-router-dom'

function Header() {
    return (
        <Flex position="fixed" width="100%" zIndex="9999" top="0" color="white" bg="#00a7ac" alignItems="center" padding="4px 10px 4px 10px"
            justifyContent="space-between">
            <Flex alignItems="center">
                <Image height="30px" mr={4} src={Home}></Image>
                <Text fontWeight="bold">Review Trend</Text>
            </Flex>
            <Flex alignItems="center">
                <Divider height="25px" borderWidth="1px" margin="0px 10px 0px 10px" orientation="vertical" borderColor="white"></Divider>
                <Image src={Person}></Image>
                <Text>Admin@gmail.com</Text>
                <Divider height="25px" borderWidth="1px" margin="0px 10px 0px 10px" orientation="vertical" borderColor="#FFFFFF"></Divider>
                <Link to="/"><Text decoration="underline">Log Out</Text></Link>
            </Flex>
        </Flex>
    )
}

export default Header
